import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
import { genericComponentProps,NewsItem } from '../interfaces';
import { getDefaultLanguage, getTranslation } from '../translation';
import { debug } from 'console';
import YouTube from 'react-youtube';

import path from 'path';

interface NewsArticleParams {
  newsId: string;
  [key: string]: string | undefined;
}

const NewsArticle: React.FC = () => {
  const { newsId } = useParams<NewsArticleParams>();
  const [selectedNewsItem, setSelectedNewsItem] = useState<NewsItem | null>(null);
  const navigate = useNavigate(); // Get the navigate function
  const location = useLocation();
  const[nextNews, setNextNews] = useState<NewsItem[]>()
  useEffect(() => {
    fetchNews(newsId)
  }, [newsId]);
  const playerOpts = {
    width: '100%', // Set your desired width here
  };

  const fetchNews = async (newsId:string | undefined) => {
    try {
      window.scrollTo(0, 0);
      const response = await fetch(`https://numbers.nikolakolevski.com/api/news/get/${newsId}`);
      const data = await response.json();
      setSelectedNewsItem(data.currentNews)
      setNextNews(data.otherNews)
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };
  if (!selectedNewsItem) {
    return  <div className="minh-100" style={{marginTop:'20rem'}}>Loading...</div>;
  }


  const renderNewsContent = (news : NewsItem | undefined , language: string ) => {
    const english = language === "en" ? true: false
    return (
      <div className='main_news_container'>
        <div style={{
          // marginBottom:'2rem',

        }}>
          <h1>{english?  news?.title : news?.title}</h1>
        </div>
        <div style={{marginBottom:'2rem'}}>
        {english?  news?.subtitle : news?.subtitle}
        </div>
        <div style={{marginBottom:'2rem'}}>
        {english?  news?.first_description : news?.first_description}
        </div>
        <div style={{marginBottom:'2rem', minWidth:'100%'}}>
          <img alt="" style={{minWidth:'100%', maxWidth:'100%'}} src={news?.src}/>
        </div>
        <div>
        {english?  news?.second_description: news?.second_description}
        </div>
        <div className="youtube_video" style={{maxWidth:'100%', minWidth:'100%', marginTop:'2rem'}} >
              <YouTube  opts={playerOpts} videoId={news?.youtube_link} />
        </div>
      </div>
    )
  }

  return (
    <div className="minh-100" >
      {/* <h2>{selectedNewsItem.title}</h2>
      <p>{selectedNewsItem.description}</p> */}

    <div id="current_news" style={{display:'block'}}>
      <div className='main_container' >
        <div className="main_news">
          {renderNewsContent(selectedNewsItem,getDefaultLanguage())}
        </div>
        <div className='other_news_header'>
          <h3>  {getTranslation("more_news")}</h3>
        </div>
        <div className='other_news'>
          {nextNews?.map((current_news:any, index:number)=>{
            return (
              <div onClick={()=>{
                const newsPath = `/news/${current_news.uuid}`;
                navigate(newsPath);
                // setIsSelected(!isSelected)
                // setSelectedNews(current_news)
                // setNextNews([currentNewsList[index+2],currentNewsList[index+3], currentNewsList[index+4] ])
              }}>
                <div className="image-container" style={{padding:'1rem'}}>

                  <img
                      alt="microphone"
                      src={current_news.src}
                      style={{ width: '100%', borderRadius:18, maxHeight:'35vh', objectFit:'cover' }}
                    />
                    <div className='news_add_container' style={{
                        position: 'absolute',
                        bottom: '10px',
                        // left: '30px',
                        width: 'auto',
                        maxWidth: '300px !important', // Note: '!important' is not needed in inline styles
                        overflowWrap: 'break-word',
                        alignItems: 'center',
                        display: 'flex',
                        /* justify-content: 'center', */
                        /* height: '3.5rem', */
                        /* fontSize: '1rem', */
                        color: 'white',
                        border: '0',
                        padding: '20px',
                        wordBreak:'break-word',
                    }}
                      ><div className='news_title'>{current_news.title}</div>

                  </div>
              </div>
              </div>
            )
          })}
        </div>
      </div>

    </div>
    </div>
  );
};

export default NewsArticle;



