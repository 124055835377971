import React, { useEffect, useState } from "react";
import { getTranslation } from "../translation";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { genericComponentProps } from "../interfaces";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import ImageSrc from "../img/office.png"; // Adjust the path accordingly
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Language } from "@mui/icons-material";

interface Employee {
  name: string;
  surname: string;
  position: string;
  tel: string;
  email: string;
  img: string;
}

export default function OurTeam({ isInHomepageView }: genericComponentProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null); // Track the selected employee
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  const { employeeId } = useParams();
  const location = useLocation()

  const [employees, setEmployees] = useState<Employee[]>([
    {
      email: "zlatkokolevski@gmail.com",
      name: "Zlatko",
      position: "CEO",
      img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",
      surname: "Kolevski",
      tel: "+389 7077 777",
    },
    {
      email: "mihaelakolevska@gmail.com",
      name: "Mihaela",
      position: "",
      img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",
      surname: "Kolevska",
      tel: "+389 7077 111",
    },
    {
      email: "sofijaveljanovska@gmail.com",
      name: "Sofija",
      position: "CEO",
      img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",

      surname: "Veljanovska",
      tel: "+389 7077 888",
    },
    {
      email: "tomekolevski@gmail.com",
      img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",
      name: "Tome",
      position: "CEO",
      surname: "Kolevski",
      tel: "+389 7077 888",
    },
    {
      email: "Nikola@gmail.com",
      name: "Sofija",
      position: "CEO",
      surname: "Krajchevski",
      img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",
      tel: "+389 7077 888",
    },
    {
      email: "vska@gmail.com",
      name: "Marta",
      position: "CEO",
      surname: "Petkovska",
      img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",
      tel: "+389 7077 888",
    },
    {
      email: "vska@gmail.com",
      name: "Marta",
      position: "CEO",
      img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",

      surname: "Petkovska",
      tel: "+389 7077 888",
    },
    // {
    //   email: "vska@gmail.com",
    //   name: "Marta",
    //   position: "CEO",
    //   surname: "Petkovska",
    //   img: "https://dotalive.org/wp-content/uploads/2024/12/asdasd.png",

    //   tel: "+389 7077 888",
    // },
  ]);
  useEffect(() => {
    console.log(employeeId);
    let parts = employeeId?.split("-") as string[];
    console.log(parts);
  
    if (employeeId) {
      const foundEmployee = employees.find(
        (emp) =>
          emp.name.toLowerCase() === parts[0] &&
          emp.surname.toLowerCase() === parts[1]
      );
  
      if (foundEmployee) {
        setShowDetails(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
        });
        setSelectedEmployee(foundEmployee);
      } else {
        // Employee not found, handle accordingly (e.g., show an error message)
        setShowDetails(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
        });
      }
    } else {
      setShowDetails(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
      });
    }
  }, [employeeId, employees]);  // Include employeeId in the dependency array
  
  const handleShowDetails = (employee: Employee) => {
    setSelectedEmployee(employee);
    setShowDetails(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can use "auto" instead of "smooth" for instant scroll
    });

    const decodedPath = decodeURIComponent(location.pathname);
debugger
    // Use navigate to update the URL
    if(decodedPath === '/our-team') {
      navigate(`/our-team/${employee.name.toLowerCase()}-${employee.surname.toLowerCase()}`);
    } else {
      navigate(`/нашиот-тим/${employee.name.toLowerCase()}-${employee.surname.toLowerCase()}`);
    }
    
  };

  const handleGoBack = () => {
    setShowDetails(false);
    setSelectedEmployee(null);
    // Use navigate to go back to the team view
    navigate("/our-team");
  };

  interface TeamMemberProps {
    employee: Employee;
    row: number;
    isMobile:boolean;
  }

  const TeamMember: React.FC<TeamMemberProps> = ({ employee, row ,isMobile }) => {
    return (
      <div
        style={{
          minHeight:  isMobile ? "auto":"270px",
          maxHeight: isMobile ? "auto":"270px",
          borderRadius: "30px",
          background: "#F6F4F2",
          minWidth: isMobile ? row === 1 ? "100%" : "50%": row === 1 ? "40%" : "23rem",
          maxWidth:  isMobile ? row === 1 ? "100%" : "50%": row === 1 ? "40%" : "",
          display: "flex",
          flexDirection: isMobile && row !=1 ? "row":"column",
          paddingRight: isMobile && row ==1 ? "2rem":"0rem",
          justifyContent: "space-between",
          transition: "background-color 0.3s ease", // Add a smooth transition
        }}
        onMouseEnter={(e) => {
          // e.currentTarget.style.display = 'none';
          const memberPhotoDiv = e.currentTarget.querySelector(
            "#member_front"
          ) as any;
          if (memberPhotoDiv) {
            memberPhotoDiv.style.display = "none";
          }
          const memberPhotoDivBack = e.currentTarget.querySelector(
            "#member_back"
          ) as any;
          if (memberPhotoDivBack) {
            memberPhotoDivBack.style.display = "flex";
          }
          const button = e.currentTarget.querySelector("#button_read") as any;
          if (button) {
            button.style.display = "flex";
          }
        }}
        onMouseLeave={(e) => {
          const memberPhotoDiv = e.currentTarget.querySelector(
            "#member_front"
          ) as any;
          if (memberPhotoDiv) {
            memberPhotoDiv.style.display = "flex";
          }
          const memberPhotoDivBack = e.currentTarget.querySelector(
            "#member_back"
          ) as any;
          if (memberPhotoDivBack) {
            memberPhotoDivBack.style.display = "none";
          }
          const button = e.currentTarget.querySelector("#button_read") as any;
          if (button) {
            button.style.display = "none";
          }
        }}
      >
        <div
          className="flexDa"
          id="member_front"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column":"row",
            width: isMobile ? "100%":"auto",
            justifyContent: "space-between",
            height: isMobile ? "auto":"100%",
            padding: "2rem 2rem 0rem 2rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontSize: "28px",
                lineHeight: "42px",
                fontWeight: "500",
              }}
            >
              {employee.name}
            </span>
            <span
              style={{
                fontSize: "clamp(16px, 2.5vw, 28px)", // Dynamic font size
                lineHeight: "clamp(24px, 3.5vw, 42px)", // Dynamic line height
                fontWeight: "500",
              }}
            >
              {employee.surname}
            </span>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "500",
              }}
            >
              {employee.position}
            </span>
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <img src={employee.img} alt="" />
          </div>
        </div>
        <div
          id="member_back"
          style={{
            display: "none",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            padding: "2rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontSize: "28px",
                lineHeight: "42px",
                fontWeight: "500",
              }}
            >
              {employee.name}
            </span>
            <span
              style={{
                fontSize: "28px",
                lineHeight: "42px",
                fontWeight: "500",
              }}
            >
              {employee.surname}
            </span>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: "500",
              }}
            >
              {employee.position}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              className="dropdown-header"
              style={{ justifyContent: "start" }}
            >
              <LocalPhoneIcon
                style={{ paddingRight: "0.5rem", color: "#013A4E" }}
              />
              <span className="tablet_mobile">{employee.tel}</span>
            </div>
            <div
              className="dropdown-header"
              style={{ justifyContent: "start" }}
            >
              <EmailIcon style={{ paddingRight: "0.5rem", color: "#013A4E" }} />
              <span className="tablet_mobile">{employee.email}</span>
            </div>
          </div>
        </div>
        <Button
          id="button_read"
          style={{
            display: "none",
            background: "#013A4E",
            color: "white",
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
          // onClick={() => {
            
          //   setShowDetails(true);
          // }}
          onClick={() => handleShowDetails(employee)}

        >
          Read more
        </Button>
      </div>
    );
  };
  return (
    <>
      {showDetails ? (
        <div id="details" >
          <div
            id="our_team"
            style={{
              padding: "0rem 10rem",
              minHeight: "35vh",
              background: "#F6F4F2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <span style={{ fontSize: "3.5rem" }}>
                {/* {getTranslation("our_team.header")} */}
                {selectedEmployee?.name + " " + selectedEmployee?.surname}
              </span>
              <span style={{ fontSize: "1.5rem", lineHeight: "3.5rem" }}>
              {selectedEmployee?.position}
              </span>
              <hr
                style={{
                  background: "#013A4E",
                  height: "2px",
                  margin: "0.3rem 0rem 0rem 0rem",
                  width: "5.5rem",
                }}
              />
            </div>
            <div
              id="employee_img"
              style={{
                display: "flex",
                alignItems: "flex-end",
                minHeight: "35vh",
              }}
            >
              <img
                style={{ width: "300px", height: "450px" }}
                src={ImageSrc}
                alt=""
              />
            </div>
          </div>
          <div
            style={{
              padding: "0rem 10rem",
              minHeight: "60vh",
              display: "flex",
              gap: "20px",
              flexDirection: "row",
              marginTop: "5rem",
              marginBottom: "0.3rem",
              boxShadow: " 0px 15px 20px 0px #1E1E1E1A",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "60vh",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                paddingLeft: "1.5rem",
              }}
            >
              <span
                style={{
                  fontSize: "32px",
                  lineHeight: "68px",
                  fontWeight: "500",
                  color: "#013A4E",
                }}
              >
                {getTranslation("our_team.employee_info_about") + " " + selectedEmployee?.name + " " + selectedEmployee?.surname}
              </span>
              <span
                style={{
                  fontSize: "24px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                Legal areas & industries
              </span>
              <span
                style={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                Real estate law · Environmental law · Real estate & construction
              </span>
              <span
                style={{
                  fontSize: "24px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                Admitted to the bar
              </span>
              <span
                style={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                2018, Poland
              </span>
              <span
                style={{
                  fontSize: "24px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                With Kolevski Law since
              </span>
              <span
                style={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                2022
              </span>
              <span
                style={{
                  fontSize: "24px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                Education
              </span>
              <span
                style={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "400",
                  color: "#013A4E",
                }}
              >
                Lazarski University, Warsaw/Poland (Postgraduate Studies in
                Environmental Law 2023)John Paul II Catholic University of
                Lublin/Chicago-Kent College of Law, Lublin/Poland (American Law
                Course 2014)John Paul II Catholic University of Lublin,
                Lublin/Poland (Mag. iur. 2014)
              </span>
            </div>
            <div style={{ width: "25%", height: "60vh" }}>
              <div
                style={{
                  borderRadius: "30px",
                  maxHeight: "170px",
                  margin: "5rem 0rem",
                  background: "#F6F4F2",
                  minHeight: "25vh",
                  padding: "2rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <span style={{ fontSize: "32px", lineHeight: "48px" }}>
                    {getTranslation("our_team.contact")}
                  </span>
                  <hr
                    style={{
                      background: "#013A4E",
                      height: "2px",
                      margin: "0.3rem 0rem 0rem 0rem",
                      width: "3rem",
                    }}
                  />
                  <div
                    className="dropdown-header"
                    style={{ justifyContent: "start", marginTop:'2rem', padding: "0.5rem 0rem" }}
                  >
                    <LocalPhoneIcon
                      style={{ paddingRight: "0.5rem", color: "#013A4E" }}
                    />
                    <span className="tablet_mobile">+389 777 7777 7</span>
                  </div>
                  <div
                    className="dropdown-header"
                    style={{ justifyContent: "start", padding: "0.5rem 0rem" }}
                  >
                    <EmailIcon
                      style={{ paddingRight: "0.5rem", color: "#013A4E" }}
                    />
                    <span className="tablet_mobile">@example@zk.com</span>
                  </div>
                  <div
                    className="dropdown-header"
                    style={{ justifyContent: "start", padding: "0.5rem 0rem" }}
                  >
                    <EmailIcon
                      style={{ paddingRight: "0.5rem", color: "#013A4E" }}
                    />
                    <span className="tablet_mobile">@example@zk.com</span>
                  </div>
                  <div
                    className="dropdown-header"
                    style={{ justifyContent: "start", padding: "0.5rem 0rem" }}
                  >
                    <EmailIcon
                      style={{ paddingRight: "0.5rem", color: "#013A4E" }}
                    />
                    <span className="tablet_mobile">@example@zk.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div id="our_team">
            <span style={{ fontSize: "3.5rem" }}>
              {getTranslation("our_team.header")}
              <hr
                style={{
                  background: "#013A4E",
                  height: "2px",
                  margin: "0.3rem 0rem 0rem 0rem",
                  width: "5.5rem",
                }}
              />
            </span>
          {/* desktopppppppppppppp */}
            <div
            className="small_screen_hide"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {employees.slice(0, 1).map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={false} employee={employees[0]} row={1} />
                    </div>
                  </>
                );
              })}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[1] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[1]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[2] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[2]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[3] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[3]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {/* <div>SHOW INFO</div> */}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[4] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[4]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[5] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[5]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[6] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[6]} row={2} />
                  </>
                ) : (
                  <></>
                )}
                {employees[7] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[7]} row={2} />
                  </>
                ) : (
                  <></>
                )}

               
              </div>
            </div>
          {/* tablet */}
                     <div
            className="mobile_screen_show"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {employees.slice(0, 1).map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={true} employee={employees[0]} row={1} />
                    </div>
                  </>
                );
              })}
         
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >
                {employees[1] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[1]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {employees[2] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[2]} row={2} />
                  </>
                ) : (
                  <></>
                )}

                {/* <div>SHOW INFO</div> */}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "50px",
                  padding: "0rem 0rem 5rem 0rem",
                }}
              >{employees[3] ? (
                <>
                  <TeamMember isMobile={false}  employee={employees[3]} row={2} />
                </>
              ) : (
                <></>
              )}
                {employees[4] ? (
                  <>
                    <TeamMember isMobile={false}  employee={employees[4]} row={2} />
                  </>
                ) : (
                  <></>
                )}
               
              </div>
            </div>
             {/* mobile */}
             <div
            className="mobile_screen_show_smallest"
              style={{
                marginTop: "5rem",
                height: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {employees.map((Employee, index) => {
                return (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "50px",
                        padding: "0rem 0rem 5rem 0rem",
                      }}
                      key={index}
                    >
                      <TeamMember key={index} isMobile={true} employee={Employee} row={1} />
                    </div>
                  </>
                );
              })}
         
            </div>
          </div>
        </>
      )}
    </>
  );
}
