import React, { ChangeEvent, useEffect, useState } from 'react';
import { getTranslation } from '../../translation';
import '../../styles/NewsComponent.css'
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from 'react-modal';

import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import Button from '@mui/material/Button';
import ImageUploading from 'react-images-uploading';

import '../../styles/NewsDetailsComponent.css'
import { NewsItem } from '../../interfaces';
import axios from 'axios';

interface newsDetailsProp {
  isEditView : any;
  openModalDialog: any;
  currentItem : NewsItem ;
  openDialog:any;
  openDialogNormal:any;
}

export default function NewsDetailsComponent({currentItem,openModalDialog, isEditView, openDialog, openDialogNormal }: newsDetailsProp) {
  const [updatedItem, setUpdatedItem] = useState(currentItem);
  useEffect(() => {
    if (currentItem.title.length > 0 ) {
      setUpdatedItem(currentItem)
    }
  }, [currentItem]);
  const [isLoading , setIsLoading] = useState<Boolean>(false)
      const handleCloseDialog = (succ :boolean) => {
        setCurrentNews( {
          created:"",
          alt:"",
          body:"",
          category:"",
          second_description:"",
          first_description:"",
          src:"",
          subtitle:"",
          title:"",
          updated:"",
          youtube_link:"",
          status:false,
          uuid:''
        })
        if (succ) {
          openDialog(false);
        } else {
          openDialogNormal(false)
        }
      };

      const [currentNews, setCurrentNews] = useState<NewsItem>({
        created:"",
        alt:"",
        body:"",
        category:"",
        second_description:"",
        first_description:"",
        src:"",
        subtitle:"",
        title:"",
        youtube_link:"",
        updated:"",
        status:false,
        uuid:''
      });

    const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
      if(isEditView) {
        const { name, value } = e.target;
        setUpdatedItem((prevCurrentNews) => ({
          ...prevCurrentNews,
          [name]: value,
        }));

      } else {
        const { name, value } = e.target;
        setCurrentNews((prevCurrentNews) => ({
          ...prevCurrentNews,
          [name]: value,
        }));

      }

      };

      const [images, setImages] = React.useState([]);
      const maxNumber = 69;

      const onChange = (imageList:any, addUpdateIndex:any) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
      };

      const customCreateDialogStyles = {
        content: {
          backgroundColor:'white',
          width: '50%',
          height: '70%',
          margin: 'auto',
          borderRadius: '8px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
          background: 'white',
          zIndex:999,
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      };

      const createNewNews = async () => {
        try {
          StartLoading()
          if (
            currentNews.first_description === "" ||
            currentNews.src===  "" ||
            currentNews.subtitle ===  "" ||
            currentNews.title === ""
          ) {

            handleOpenInfo()
          } else {
            await axios.post(`https://numbers.nikolakolevski.com/api/news/create`, currentNews, {
              headers: {
                'Content-Type': 'application/json',
              },
            });

            StopLoading()
            handleCloseDialog(true)
          }

          // handleOpenSuccessfull()
          // clearSelectedService()
          // refreshPackages()

        } catch (error) {
          // setError(error.message);
          StopLoading()
          // handleOpenFailed()
        }
      };
      const updateNews = async () => {
        try {
          StartLoading()
          if (
            currentItem.first_description === "" ||
            currentItem.src===  "" ||
            currentItem.subtitle ===  "" ||
            currentItem.title === ""
          ) {

            handleOpenInfo()
          } else {
            await axios.post(`https://numbers.nikolakolevski.com/api/news/update/${currentItem.uuid}`, updatedItem, {
              headers: {
                'Content-Type': 'application/json',
              },
            });

            StopLoading()
            handleCloseDialog(true)
          }

          // handleOpenSuccessfull()
          // clearSelectedService()
          // refreshPackages()

        } catch (error) {
          // setError(error.message);
          StopLoading()
          // handleOpenFailed()
        }
      };

      const [info, setInfo] = useState(false);
        // success
      const handleOpenInfo = () => {
        setInfo(true);
      };

      const handleCloseInfo= () => {
        setInfo(false);
      };

      const StartLoading = () => {
        setIsLoading(true);
      };

      const StopLoading = () => {
        setIsLoading(false);
      };
  return (
    <Modal
    isOpen={openModalDialog}
    contentLabel="Dialog"
    style={customCreateDialogStyles}
  >
    <div>
    <div className='buttons-modal'>
    <FormControlLabel
          control={<Switch checked={isEditView ? updatedItem.status : currentNews.status} onChange={()=>{
            if(isEditView) {
              setUpdatedItem(currentItem)
              setUpdatedItem((prevCurrentNews) => ({
                ...prevCurrentNews,
                status:!updatedItem.status
              }));

            } else {
              setCurrentNews((prevCurrentNews) => ({
                ...prevCurrentNews,
                status:!currentNews.status
              }));

            }

          }} />}
          label={isEditView ? getTranslation(updatedItem.status ? 'buttons.active' :'buttons.not_active') : getTranslation(currentNews.status ? 'buttons.active' :'buttons.not_active') }
        />
    <Button
                style={{margin:'0.5rem 0rem 0.5rem 0.5rem', color:'black', background:'transparent',border:'1px solid black'}}

                onClick={(e:any)=>{
                  handleCloseDialog(false)
                  }} variant="outlined">{getTranslation("buttons.close")}</Button>
                           <Button
                style={{margin:'0.5rem 0rem 0.5rem 0.5rem', background:'darkgreen', color:'white'}}

                onClick={(e:any)=>{
                  if (isEditView) {
                    updateNews()

                  } else {
                    createNewNews()

                  }
                  }} variant="outlined">{getTranslation(isEditView ? "buttons.save" : "buttons.create")}</Button>
    </div>
      <div className='textfield_form'>
          <div className='textfield_container'>
              <TextField
                name="title"
                className='textfield'
                required
                value={isEditView ? updatedItem.title : currentNews.title}
                id="outlined-required"
                label={getTranslation('labels.title')}
                onChange={handleValueChange}
              />
              </div>
              <div className='textfield_container'>
              <TextField
                name="subtitle"
                className='textfield'
                required
                value={isEditView ? updatedItem.subtitle : currentNews.subtitle }
                id="outlined-required"
                label={getTranslation('labels.subtitle')}
                onChange={handleValueChange}
              />
              </div>
              <div className='textfield_container'>
              <TextField
                name="youtube_link"
                className='textfield'
                required
                value={isEditView ? updatedItem.youtube_link : currentNews.youtube_link }
                id="outlined-required"
                label={getTranslation('labels.youtube_link')}
                onChange={handleValueChange}
              />
              </div>
              <div className='textfield_container'>
              <TextField
              name="first_description"
              className='textfield'
              value={isEditView ? updatedItem.first_description :currentNews.first_description}
              id="outlined-multiline-static"
              label={getTranslation('labels.main_blog')}
              multiline
              rows={4}
              onChange={handleValueChange}
            />
          </div>
          <div>

          {/* <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <button
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Click or Drop here
                  </button>
                  &nbsp;
                  <button onClick={onImageRemoveAll}>Remove all images</button>
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image['data_url']} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button onClick={() => onImageUpdate(index)}>Update</button>
                        <button onClick={() => onImageRemove(index)}>Remove</button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading> */}
          </div>
          <div className='textfield_container'>
          <TextField
              name="src"
              className='textfield'
              required
              value={isEditView ? updatedItem.src :currentNews.src}
              id="outlined-required"
              label={getTranslation('labels.src')}
              onChange={handleValueChange}
              />
          </div>
          <div className='textfield_container'>
              <TextField
              name="second_description"
              className='textfield'
              id="outlined-multiline-static"
              label={getTranslation('labels.text')}
              multiline
              rows={4}
              value={isEditView ? updatedItem.second_description :currentNews.second_description}
              placeholder="text"
              onChange={handleValueChange}
            />
          </div>
        </div>

    </div>

    <Snackbar open={info} autoHideDuration={3000} onClose={handleCloseInfo}>
        <Alert sx ={{
          display:'flex',
          alignItems:'center'
        }} onClose={handleCloseInfo} severity="error" variant="filled">
         <span>{getTranslation('toast.empty_fields')}</span>
        </Alert>
      </Snackbar>
    </Modal>

  )
}
